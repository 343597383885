@import url("https://fonts.googleapis.com/css2?family=Overpass:ital,wght@0,100;0,200;1,100;1,200&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: Luthon;
    src: url(./assets/font/Luthon-Southard-Script.ttf) format("truetype");
  }
  @font-face {
    font-family: serif;
    src: url(./assets/font/Luthon-Southard-Serif.ttf) format("truetype");
  }
  body {
    @apply bg-primary font-overpass text-white;
  }
  h1 {
    @apply text-8xl sm:text-9xl lg:text-10xl xl:text-11xl 2xl:text-12xl font-luthon;
  }
  h2 {
    @apply text-4xl sm:text-5xl xl:text-7xl 2xl:text-8xl font-luthon;
  }
  h3 {
    @apply text-2xl sm:text-3xl xl:text-4xl 2xl:text-5xl font-light sm:font-thin text-gray-800;
  }
  h4 {
    @apply text-xl sm:text-2xl xl:text-3xl 2xl:text-4xl;
  }
  h5 {
    @apply text-lg sm:text-xl xl:text-2xl 2xl:text-3xl;
  }
  h6 {
    @apply text-base sm:text-lg xl:text-xl 2xl:text-2xl;
  }
  p {
    @apply text-sm sm:text-base xl:text-lg 2xl:text-xl;
  }
  small {
    @apply text-xs sm:text-sm xl:text-base 2xl:text-lg;
  }
  li {
    @apply text-base sm:text-lg xl:text-xl 2xl:text-2xl;
  }
  button {
    @apply bg-white bg-opacity-20 rounded focus:outline-none;
  }
  hr {
    @apply border-white;
  }
  /* div {
    @apply border;
  } */
}

@layer components {
  /* UI */
  .FlexCenter {
    @apply flex items-center justify-center;
  }
  .FlexJustify {
    @apply flex justify-center;
  }
  .FlexAlign {
    @apply flex items-center;
  }
  /* Components */
  .Hover {
    @apply transform hover:-translate-y-1 duration-500;
  }
  .ImgHover {
    @apply object-contain transform hover:scale-95 duration-1000 rounded shadow-sm;
  }
  .Btn {
    @apply text-base sm:text-lg xl:text-xl 2xl:text-2xl hover:bg-opacity-30 transform hover:-translate-y-2 duration-500 shadow-sm hover:shadow cursor-pointer py-2 px-6;
  }
  .TagBtn {
    @apply text-xs sm:text-sm xl:text-base 2xl:text-lg cursor-default m-1 py-1 px-3;
  }
  .CardBg {
    @apply bg-gray-300 bg-opacity-20 rounded shadow-sm m-2 xl:m-4 p-4 sm:p-8;
  }
}
